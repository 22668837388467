import type { Client, ExchangeInput, ExchangeIO } from 'urql';
import { createClient, fetchExchange, ssrExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import { userQueryDocument } from '~/gql/queries';
import {
  type LoginMutation,
  type CreateCarStockFavoriteMutation,
  type DestroyCarStockFavoriteMutation
} from '~/gql/generated/graphql';

const cacheKeys = {
  BuddicaScore: () => null,
  MainCarStockEquipment: () => null,
  SubCarStockEquipment: () => null,
  MypageDealTask: () => null,
  LandTransportCost: () => null,
  Address: () => null,
  S3DirectUploadParams: () => null,
  CarStockName: () => null,
  OwnedCarOptionOption: () => null,
  OwnedCarImageType: () => null,
  SellingNeedDocumentSelectedDataItem: () => null,
  SellingNeedDocumentQuestion: () => null,
  SellingNeedDocumentChoice: () => null,
  SellingDealReservationDeliveryReservationData: () => null,
  SellingDealReservationDeliveryPreferredTimeOption: () => null,
  SellingDealReservationBringInDealerBranchOption: () => null,
  ZipCodeAddress: () => null,
  OwnedCarImageGroup: () => null
};

export const client = (baseUrl: string, mutationInterceptExchange: (input: ExchangeInput) => ExchangeIO): Client => {
  return createClient({
    url: `${baseUrl}/api_user/graphql`,
    exchanges: [
      cacheExchange({
        updates: {
          Mutation: {
            createCarStockFavorite(result, _args, cache, _) {
              const resultCarStock = (result as CreateCarStockFavoriteMutation)?.createCarStockFavorite
                ?.carStockFavorite?.carStock;
              if (!resultCarStock) return;

              cache.updateQuery({ query: userQueryDocument }, (data) => {
                if (!data?.viewer) return data;
                const oldFavoritedCarStocks = data?.viewer?.favoritedCarStocks || [];
                const newFavoritedCarStocks = oldFavoritedCarStocks.concat(resultCarStock);
                data.viewer.favoritedCarStocks = newFavoritedCarStocks;
                return data;
              });
            },
            destroyCarStockFavorite(result, args, cache, _) {
              const isSuccess = (result as DestroyCarStockFavoriteMutation)?.destroyCarStockFavorite?.isSuccess;
              if (!isSuccess) return;

              cache.updateQuery({ query: userQueryDocument }, (data) => {
                if (!data?.viewer?.favoritedCarStocks) return data;
                data.viewer.favoritedCarStocks = data.viewer.favoritedCarStocks.filter(
                  (carStock) => carStock.id !== (args?.input as { carStockId: string })?.carStockId
                );
                return data;
              });
            },
            login(result, _args, cache, _) {
              const resultUser = (result as LoginMutation).login?.user;
              if (!resultUser) return;

              cache.link('Query', 'viewer', cache.keyOfEntity({ __typename: 'User', id: resultUser.id }));
            }
          }
        },
        keys: cacheKeys
      }),
      mutationInterceptExchange,
      fetchExchange
    ],
    fetchOptions: () => {
      return {
        credentials: 'include'
      };
    }
  });
};

export const ssrClient = ({ baseUrl }: { baseUrl: string }): Client => {
  return createClient({
    url: `${baseUrl}/api_user/graphql`,
    exchanges: [cacheExchange({ keys: cacheKeys }), fetchExchange, ssrExchange({ isClient: false })]
  });
};
